/* Connection Font Icons */
@font-face {
    font-family: 'FontAwesome';
    src: url('../fonts/fontawesome-webfont.eot');
    src: url('../fonts/fontawesome-webfont.eot') format('embedded-opentype'), 
    url('../fonts/fontawesome-webfont.woff2') format('woff2'), 
    url('../fonts/fontawesome-webfont.woff') format('woff'), 
    url('../fonts/fontawesome-webfont.ttf') format('truetype'), 
    url('../fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular') format('svg');
    font-weight: 400;
    font-style: normal
}
/* Connection Font Icons */

body {
    margin: 0;
}

h5 {
    font-size: 18px;
    @media screen and (max-width: 1024px) {
        font-size: 16px;
    }
}
* {
	box-sizing: border-box;
}

*:after, *:before {
	display: block;
	line-height: 1;
}

textarea, 
button, 
select, 
input[type="text"], 
input[type="email"], 
input[type="search"],
input[type="password"],
input[type="file"],
input[type="submit"] {
	border-radius: 0px;
	-webkit-appearance: none;
}

input[type="text"],
input[type="password"],
input[type="email"],
textarea {
    width: 100%;
    min-height: 50px;
    font-size: 16px;
    padding: 15px;
    border: 1px solid #d1b4aa;
    margin-bottom: 25px;
    resize: none;
    &::placeholder {
        color: #b2b2b3;
    }
    @media screen and (max-width: 1024px) {
        min-height: 45px;
        font-size: 14px;
        padding: 12px 15px;
        margin-bottom: 20px;
    }
    @media screen and (max-width: 768px) {
        min-height: 40px;
        padding: 10px 15px;
        margin-bottom: 15px;
    }
}

.btn {
    display: table;
    height: 58px;
    background-color: #47271b;
    font-size: 16px;
    color: #ffffff;
    text-transform: uppercase;
    border: 1px solid #47271b;
    padding: 20px 40px;
    line-height: 1;
    cursor: pointer;
    text-decoration: none;
    transition: 300ms;
    &:hover {
        background-color: #f6f0ee;
        color: #47271b;
    }
    @media screen and (max-width: 1024px) {
        height: 48px;
        font-size: 14px;
        padding: 16px 30px;
    }
    @media screen and (max-width: 768px) {
        height: 45px;
        padding: 15px 25px 14px;
    }
}

.close {
    width: 18px;
    height: 18px;
    position: absolute;
    right: 8px;
    top: 10px;
    opacity: 1;
    outline: none;
    &:before, &:after {
        content: '';
        width: 20px;
        height: 2px;
        background-color: #47271b;
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        margin: -1px auto 0;
        transition: 300ms;
    }
    &:before {
        transform: rotate(45deg);
    }
    &:after {
        transform: rotate(-45deg);
    }
    &:hover {
        &:before {
            transform: rotate(135deg);
        }
        &:after {
            transform: rotate(45deg);
        }
    }
}